.App {
  text-align: center;
  font-family: ''Segoe UI'';
}

.bg-blue {
  background: #0D4A61;
}
.bg-yellow {
  background: #fdc32c;
}
.text-blue {
  color:  #0D4A61;
}

.text-white {
  color:  #FFFFFF;
}
.text-yellow {
  color: #fdc32c;
}

.note_text{
  font-family: "Segoe UI";
  letter-spacing: 0px;
  color: #0D4A61 !important;
}

.enquiry_body {
  /* background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('./images/enquiry_bg.jpg'); */
  background: #F3F6FB;
  background-repeat: no-repeat;
  background-size: cover;
 
  min-height: 90vh;
}

.row {
  margin: 0;
}

.enquiry_form_body {
  /* background-color: rgb(187, 185, 185); */
  background-color: #0d4a61;
  box-shadow: 2px 2px 5px #000000;
  border-radius: 5px;
  margin: 70px 20px;
  justify-content: center;
  
}

.enquiry_form_body h3{
  color: #fdc32c;
  text-transform: uppercase;
  padding: 20px 0 15px 0;
  /* font-weight: bold;  */
}

.row label {
  text-align: right;
  font-weight: bold;
}

.custom-file-label {
  text-align: left;
}



.panel div h4{
   margin-top: -150px;
   font-weight: 700;
  /* font-size: 24px; */
  line-height: 28px;
  font: normal normal normal 28px Sitka Small;
  color: #FFFFFF;
}

.panel h3  {
 
  font-weight: 700;
  /* font-size: 24px; */
 
  font: normal normal normal 35px Sitka Small;
  color: #FFFFFF;
}

.panel h3 a {
  border-radius: 20px;
  margin-left: 10px;
}

.panel p {
 
  font: normal normal normal 14px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
 
  
}

.quotation_panel h4{
  margin-top: -150px;
  font-weight: 700;
  font: normal normal normal 28px Sitka Small;
  color: #FFFFFF;
}

.enquiry_table {
  box-shadow: 0 1px 5px rgba(0,0,0,.08);
}

.borderless td, .borderless th {
  border: none;
}

.profile_account{
  cursor: pointer;
  padding: 0.8rem 1rem 0.8rem 1.5rem;
}

.profile_active{
  color: #FFFFFF !important;
  background: #FDC32C 0% 0% no-repeat padding-box;
}

.jss99 {
  /* color: #999; */
  margin: 0;
  margin-top: 0;
  padding-top: 10px;
  margin-bottom: 0;
  font: normal normal bold 14px 'Segoe UI';
  letter-spacing: 0px;
  color: #5D5D5D;
}

.jss102 {
  /* color: #999; */

  font: normal normal bold 16px 'Segoe UI';
  letter-spacing: 0px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #0D4A61;
  text-transform: capitalize;
}

.jss103 {
  /* color: #999; */

  font: normal normal bold 20px 'Segoe UI';
  letter-spacing: 0px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #0D4A61;
  text-transform: capitalize;
}

.jss101 {
 
  margin-top: 10px;
  margin-bottom: 60px;
  min-height: auto;
  margin-bottom: 3px;
  text-decoration: none;
  font: normal normal normal 40px 'Segoe UI';
  letter-spacing: 0px;
  color: #5D5D5D;
}

.dashboard_body{
  background: #F3F6FB !important;
}

.dashboard_tables{
  padding-top: 1rem !important;
}

.card-top {
  position: absolute;
  padding: 15px;
  right: 10px;
  /* background: linear-gradient(60deg, #0f78a0, #085775); */
  color: #a6a6a6;
  border-radius: 50%;
  border: 2px solid #0D4A61;
  /* border: 1px solid #a6a6a6; */
  /* transform: translateY(-40px); */
  
}

.card_shadow {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 7px !important; 
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 0px;
  margin-bottom: 30px;
}

.table_top_head {
  text-align: left;
  font: normal normal bold 22px 'Segoe UI';
  letter-spacing: 0px;
  color: #0D4A61;
  margin-top: 0px;
  min-height: auto;
 
  margin-bottom: 3px;
  text-decoration: none;
}

.icon-top{
  border-radius: 50%;
  border: 2px solid #0D4A61;
  padding: 0px 7px 6px 7px;
}

.table_top_subhead {
  font: normal normal normal 14px 'Segoe UI';
  letter-spacing: 0px;
  color: #0D4A61;
  margin: 0;
  
  margin-top: 0;
  margin-bottom: 0;
}



.table_top{
  padding: 2px;
 
  border-radius: 7px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
 
}

.metrix {
 

}

.metrix_heading{
  margin-top: -145px;
  position: relative;
  height:20px;
  margin-bottom: 0rem !important;
  font: normal normal normal 28px Sitka Small;
  letter-spacing: 0px;
  color: #FFFFFF;
}


.card-header{
  background-color: #FFFFFF !important;
}


.metrix .card-header, .metrix .card_shadow{
  
  box-shadow: 0 2px 10px #00000029;
}

.card_shadow hr {
  margin: 0px;
  border: 0.5px solid #153D77;
  color: #153D77;
  position: absolute;
  width: 99%;
  right: 1px;
  margin-top: 0.5rem !important;
}

.list_table {
  margin-top: -50px;
}

.profile_table{
  margin-top: -70px;
}

.breadcrumb {
  background: white;
}


/* profile */
.checkbox_label {
  color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.sb_btn {
  border: 1px solid #0D4A61;
  border-radius: 7px;
  padding: 5px 15px;
  font: normal normal 600 14px "Segoe UI";
  letter-spacing: 0px;
  color: #0D4A61;
  float: right;
  width: auto;
  cursor: pointer;
}

.sb_btn:focus {
 outline: none !important;
 cursor: pointer;
 background-color: #0D4A61 !important;
 color:#FFFFFF !important;
 box-shadow: none !important;
}

.sb_btn:hover{
  outline: none !important;
  cursor: pointer;
  background-color: #0D4A61;
  color:#FFFFFF !important;
}

.btn-outline-info{
  color: #0D4A61 !important;
}

.sb_btn1 {
  border: 1px solid #FDC32C !important;
  border-radius: 7px !important;
  font: normal normal 600 18px "Segoe UI";
  letter-spacing: 0px;
  color: #FDC32C !important;
  text-transform: capitalize;
}

.sb_btn1:focus{
  background-color: #FDC32C !important;
  color: white !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}


.sb_btn1:hover{
  background-color: #FDC32C !important;
  color: white !important;
}

.hoverimage:hover {
  opacity: .5;
}

.table-wrapper{
  overflow-y: scroll;
  height: 150px;
 
}

.tables_wrapper{
 
  height: 400px;
}


.alt_text{
  color: #0D4A61 !important;
  font: normal normal normal 16px 'Segoe UI';
  letter-spacing: 0px;
}

.table td{
  padding: 0.85rem !important;
  font: normal normal 600 14px 'Segoe UI';
  letter-spacing: 0px;
  color: #5D5D5D;
  vertical-align: middle;
}

.page-link{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: normal normal 700 12px 'Segoe UI' !important;
  letter-spacing: 0px;
  color: #0D4A61;
  padding: 5px 20px 5px 20px !important;
  border: none !important;
  box-shadow: none !important; 
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border:1px solid #0D4A61 ;
}

.page-link1{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: normal normal 700 12px 'Segoe UI' !important;
  letter-spacing: 0px;
  color: #0D4A61;
  padding: 5px 20px 5px 20px !important;
  border: none !important;
  box-shadow: none !important; 
  border:1px solid #0D4A61 ;
  border-top-left-radius: 7px ;
  border-bottom-left-radius: 7px;
  cursor: pointer;
}

.page-link1:hover{
  outline: none !important;
  border: none !important;
  box-shadow: none !important; 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: normal normal 700 12px 'Segoe UI' !important;
  letter-spacing: 0px;
  color: #0D4A61;
  padding: 5px 20px 5px 20px !important;
}

.page-link:hover{
 outline: none !important;
 border: none !important;
 box-shadow: none !important; 
 background: #FFFFFF 0% 0% no-repeat padding-box;
 font: normal normal 700 12px 'Segoe UI' !important;
 letter-spacing: 0px;
 color: #0D4A61;
 padding: 5px 20px 5px 20px !important;
 
}

.page-link:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important; 
   
}

.page-link1:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;   
}

.pagination{
  border: 1px solid #0D4A61 !important;
  width: fit-content;
  border-radius: 7px;
}

.page-no{
  border-left: 1px solid #0D4A61 !important;
  border-right: 2px solid #0D4A61 !important;
  padding: 5px 25px 5px 25px !important;
  font: normal normal 700 12px 'Segoe UI' !important;
  letter-spacing: 0px;
  color: #0D4A61;
}

.page-no:hover{
  border-left: 1px solid #0D4A61 !important;
  border-right: 2px solid #0D4A61 !important;
  padding: 5px 25px 5px 25px !important;
  font: normal normal 700 12px 'Segoe UI' !important;
  letter-spacing: 0px;
  color: #0D4A61;
}


.not-allowed {
  pointer-events: auto! important;
  cursor: not-allowed! important;
}

.selected_files{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border: 0.5px solid #BFBFBF;
  border-radius: 7px;
}

.no_data_text{
  text-align: center;
  font: normal normal 500 16px Segoe UI;
  letter-spacing: 0px;
  color: #0D4A61;
}





