.sub-btn {
  font-weight: bold;
}

fieldset 
{
  border: 1px solid #ddd !important;
  margin: 0;
  padding: 10px;       
  position: relative;
  border-radius:4px;
  background-color:#f5f5f5;
  padding-left:10px!important;
  margin-bottom: 15px;
}	

legend {
  text-align: left;
  font-size:14px;
  font-weight:bold;
  margin-bottom: 0px; 
  width: 35%; 
  color: #0d4a61;
  border: 1px solid #ddd;
  border-radius: 4px; 
  padding: 5px 5px 5px 10px; 
  background-color:#fdc32c;
}


.enquiry_remv_btn{
  background-color:#fdc32c;
  border: 1px solid #ddd;
  float: right !important;
  font: normal normal 600 16px "Segoe UI";
  border-radius: 7px !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  padding: 0px 15px 0px 15px;
}



.enquiry_remv_btn:focus{
  background-color:#fdc32c;
  border: 1px solid #ddd;
  color: #FFFFFF;
  outline: none !important;
}

.enquiry_remv_btn:hover{
  background-color:#fdc32c;
  border: 1px solid #ddd;
  color: #FFFFFF;
  outline: none !important;
}

.enquiry_sub_btn{
  background-color:#fdc32c;
  border: 1px solid #ddd;
  float: right !important;
  font: normal normal 600 16px "Segoe UI";
  border-radius: 7px !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  padding: 5px 15px 5px 15px;
  margin-right: 4rem !important;
  cursor: pointer;
}

.enquiry_sub_btn:focus{
  background-color:#fdc32c;
  border: 1px solid #ddd;
  float: right !important;
  font: normal normal 600 16px "Segoe UI";
  border-radius: 7px !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  padding: 5px 15px 5px 15px;
  margin-right: 4rem !important;
  outline: none !important;

}




.create_enquiry_span{
  color:'#ef6c00';
}

.enquiry_list_th{
  color:'#0d4a61';
}

.enquiry_list_tbody{
  border-bottom:'1px solid #dee2e6';
}

.helper_text{
  color: #808080;
  font-size: small;
}

.img_modal{
  max-width: 100% !important;
  height: auto !important;
  margin-top: 1.75rem;
  /* margin-right: 0rem !important;
  margin-bottom: 1.75rem;
  margin-left: 0rem !important; */
}

.img_modal_content{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
 
}

.img_modal_body{
  background: transparent !important;
  display: inline-block !important;
}

.gallery_modal{
  background: rgb(27, 27, 27) !important;
}

.img{
  max-width:100%; 
  max-height:100%;
}

.create__enq__hr{
  margin: 0px;
 
  border-width: 1px !important;
  border: 1px solid #E8E8E8 !important;
  position: absolute;
  width: 100%;
 
  margin-top: 0.5rem !important;
}

.enq_form .ant-form-item-label{
text-align: left !important;
}

.line__items{
background-color: #F5F5F5 !important;
}

.enq_form .ant-form-item-label > label{
font: normal normal 600 14px "Segoe UI";
letter-spacing: 0px;
text-transform: capitalize;
}

