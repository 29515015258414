* {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

.sidebar {
    position: fixed;
    left: -250px;
    width: 250px;
    height: 100%;
    /* background: #022e3b;  */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 0px 6px #00000029;
    transition: all .5s ease;
}

.sidebar header  {
    font-size: 22px;
    text-align: center; 
  
    
    /* line-height: 70px; */
    /* background: #001418; */
    /* user-select: none; */
}

.sidebar header div {
    text-align: center;
    background: white;
    margin:12% 25% 20% 20%;
    width: 120px;
    border-radius: 100%;
    box-shadow: 0px 0px 6px #00000029;
}

.sidebar ul a {
    display: block;
    height: 100%;
    /* width: 100%; */
    line-height: 40px;
    font-size: 16px;
    color: #0D4A61;
    font-weight: 700;
    font-family: Segoe UI !important;
    padding-left: 20px;
    border-radius: 5px;
    border: 1px solid white;
    margin: 10px;
    /* box-sizing: border-box; */
    /* border-top: 1px solid rgba(255, 255, 255, .1); */
    /* border-bottom: 1px solid black; */
    transition: .2s;
}

.navItems:hover a {
    padding-left: 35px;
    background:#E9ECEF;
    text-decoration: none;  
}
.navItems:hover i {
    color: white !important;
}

.sidebar ul a i {
    margin-right: 12px;
    color: #0D4A61;
}



#check {
    display: none;
}

label #btn,label #cancel {
    position: absolute;
    cursor: pointer;
    background: #042331;
    border-radius: 3px;
}

label #btn{
    left: 40px;
    top: 25px;
    font-size: 35px;
    color: #fdc32c;
    padding: 6px 12px;
    transition: all .5s;
}

label #cancel{
    z-index: 1111;
    left: -195px;
    top: 17px;
    font-size: 30px;
    color: #0a5275;
    padding: 4px 9px;
    transition: all .5s ease;
}

#check:checked ~ .sidebar {
    left: 0;
}

#check:checked ~ label #btn {
    left: 250px;
    opacity: 0;
    pointer-events: none;
}

#check:checked ~ label #cancel {
    left: 195px;
}

#check:checked ~ section {
    margin-left: 250px;
}

section {
    /* background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(../images/enquiry_bg.jpg); */
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    background-color: #F3F6FB;
    transition: all .5s ease;
    /* background-attachment: fixed; */
    /* padding: 150px; */
    /* padding-top: 90px; */
    /* margin-top: 100px; */
}

.layout_header {
    background : #0d4a61 !important;
    height: 207px;  
    box-shadow: 0px 3px 6px #00000029;
    top: 0;
    right: 0;
    width: 100%;
}

.layout_footer {
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
    position: fixed;
    top: 90vh;
    right: 20px;
    width: 80%;
}

.dashboard_layout_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 75px;
    width: 100%;
    background-color: #F3F6FB;
}


.dashboard_layout_footer a{
    color: #0D4A61 !important;
    font: normal normal normal 12px Sitka Small;
    font-weight: 600;
    letter-spacing: 0px;
    padding-top: 2rem;
    
}


.layout_footer > a {
    font-family: 'Segoe UI' !important;
    font-weight: 400 !important;
    color:#0D4A61 !important;
    opacity: 1;
    vertical-align: middle;

}


.layout_header h3 {
    position: absolute;
    top: 40px;
    left: 115px;
    /* width: 150px; */
    /* height: 19px; */
    font: normal normal bold 22px/19px Rockwell;
    letter-spacing: 0.48px;
    color: #1A5865;
    opacity: 1;
}

.nav_logout {
    margin-top: 20%;
    width: 100%;
}

.profile{
    text-align: center;
    font-size: 25px;
    position: absolute;
    background: white;
    right: 60px;
    top:25px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.layout_header .input_box {
    position: absolute;
    padding: 3px 20px;
    top: 30px;
    border: 0;
    right: 90px;
    text-transform: capitalize;
    background: transparent;
    letter-spacing: 0.68px;
    font:bold 15px Rockwell;
    line-height: 25px;
    cursor: pointer !important;
}

.layout_header .dropdown-menu{
    
    top: 1px !important;
    margin-left: -10rem;
   
}



.layout_header .dropdown-item{
   
    font: normal normal 600 14px Segoe UI;
    letter-spacing: 0px;
    color: #0D4A61;
}

.layout_header .dropdown-item{
    background-color: white;
    color: #0D4A61;
}


.layout_header .input_box1 {
    position: absolute;
    padding: 3px 20px;
    top: 30px;
    border: 0;
    right: 40px;
    text-transform: capitalize;
    background: transparent;
    letter-spacing: 0.68px;
    font:bold 15px Rockwell;
    line-height: 25px;
    cursor: pointer !important;
}
/* .layout_header .input_box input {
    width: 220px;
    float: right;
    height: 27px;
    border: 0;
    color:#a6a6a6;
    background: transparent;
    border-radius: 0 12px 12px 0;
} */

.layout_header .logo {
    position: absolute;
    left: 55px;
}

.row label {
    text-align: left;
}

.sidebar > ul {
    padding-left: 0rem;
}

